<template>
  <div>
    <CompanyInfo
      :images="images"
      :companyBrandColour="companyBrandColour"
      :companyLogo="companyLogo"
    >
      <template #companyName>Takeda</template>
      <template #date>2017 - Ongoing</template>
      <template #content>
        <ul class="dashed">
          <li>
            Used our interactive iPad voting system to help with educating healthcare
            professionals on Takeda’s patient profiles portfolio. This helped to provide
            immunodeficiency awareness at a series of European Congresses.
          </li>
          <li>
            We supported the booth teams on site by actively engaging healthcare
            professionals using the interactive iPad voting system.
          </li>
          <li>
            Training was given to exhibition booth staff prior to congress using our
            interactive software tools. This was an invaluable way to create excitement,
            build a great valued team, give confidence and enhance communication skills
            for company representatives.
          </li>
          <li>
            Our moderator was used at their summit meeting in order to build excitement
            and anticipation prior to the opening of congress.This was done by explaining
            the whole training programme, how it would be effective in supporting
            management goals and helping employees by telling them what to expect during
            the forthcoming exhibition days.
          </li>
        </ul>
      </template>
    </CompanyInfo>
  </div>
</template>

<script>
import CompanyInfo from "@/components/Portfolio/CompanyInfo.vue";
export default {
    name: 'TakedaItem',
  components: { CompanyInfo },
  data() {
    return {
      companyBrandColour: "#EE1B24",
      companyLogo: require("@/assets/images/portfolio/takeda-logo.svg"),
      images: [
        {
          id: 5,
          responsiveImages: require("@/assets/images/portfolio/takeda/1.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0)",
          pictureDetails: "TAKEDA ANNUAL SUMMIT (2019) BRUSSELS",
        },
        {
          id: 1,
          responsiveImages: require("@/assets/images/portfolio/takeda/2.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0)",
          pictureDetails:
            "BOOTH TRAINING FOR EUROPEAN SOCIETY FOR IMMUNODEFICIENCIES (2019) IN BRUSSELS",
        },
        {
          id: 2,
          responsiveImages: require("@/assets/images/portfolio/takeda/3.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0)",
          pictureDetails: "EUROPEAN SOCIETY FOR IMMUNODEFICIENCIES (2019) BRUSSELS",
        },
        {
          id: 3,
          responsiveImages: require("@/assets/images/portfolio/takeda/4.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0)",
          pictureDetails: "EUROPEAN SOCIETY FOR IMMUNODEFICIENCIES (2019) BRUSSELS",
        },
        {
          id: 4,
          responsiveImages: require("@/assets/images/portfolio/takeda/5.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0)",
          pictureDetails: "EUROPEAN SOCIETY FOR IMMUNODEFICIENCIES (2019) BRUSSELS",
        },
        {
          id: 1,
          responsiveImages: require("@/assets/images/portfolio/takeda/6.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0)",
          pictureDetails:
            "INTERNATIONAL PRIMARY IMMUNODEFICIENCIES CONGRESS (2019) MADRID",
        },
      ],
    };
  },
};
</script>
